/* istanbul ignore file */
import axios from "axios";
import {NFLAPIConfig} from "./NFLAPIConfig/NFLAPIConfig";

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
    (response) => {
        // Catches when Content API returns incorrectly returns a 200 on a 404
        if (response.data.errorCode === 404) {
            const error = new Error(response.data);

            throw error;
        }
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const authToken = await NFLAPIConfig.getAuthToken();
            axiosInstance.defaults.headers.common.Authorization = `Bearer ${authToken.accessToken}`;
            return axiosInstance(originalRequest);
        }
        return Promise.reject(error);
    }
);

export {axiosInstance};
