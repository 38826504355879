// @ts-nocheck
import {NativeModules, Platform} from "react-native";
import defer from "defer-promise";

// utils
import {getMD5Hash} from "./getMD5Hash";
import {
    parseCreateAccountError,
    parseLoginError,
    parseUpdateUserError,
} from "./utils";
import {GigyaWebSDK} from "./GigyaWebSDK";
import {GigyaRestSDK} from "./GigyaRestSDK";

let {GigyaModule} = NativeModules;

// create a gigya javascript SDK
if (Platform.OS === "web") {
    // @ts-expect-error FixMe
    GigyaModule = GigyaWebSDK.shared;
} else if (Platform.isTV) {
    // @ts-expect-error FixMe
    GigyaModule = GigyaRestSDK.shared;
}

export const GIGYA_CANCELED_ERROR_CODE = "200001";
export const GIGYA_EXISTING_LOGIN_ERROR_CODE = "403043";
export const GIGYA_INCOMPLETE_REGISTRATION_ERROR_CODE = "206001";

export type LoginParams = {
    loginMode?: string;
    provider?: string;
    regToken?: string;
    icampaign?: string;
};

export type RegisterUserParams = {
    birthDay: number;
    birthMonth: number;
    birthYear: number;
    country: string;
    email: string;
    favoriteTeam?: string;
    firstName: string;
    lastName: string;
    optIn: boolean;
    password: string;
    tos: boolean;
    zip: string;
    regSource?: string;
};

export type SendWatchHistoryParams = {
    contentId: string;
    contentType: string;
    deviceId: string;
    progress: string;
    timestamp: number;
};

export type ConflictingAccountResponse = {
    loginId: string;
    loginProviders: [];
};

export type SocialLoginResponse = {
    regToken: string;
    errorCode: number;
    accountInfo: string;
    UID: string;
};

export type UpdateGigyaAccountParams = {
    clientId?: string;
    clientSecret?: string;
    data?: {
        display?: {
            showScores?: boolean;
        };
        league?: {
            favoriteTeams?: string[] | null | undefined;

            followed_players?: string[];
        };
    };
    deviceId?: string;
    profile?: {
        birthDay?: number | null | undefined;
        birthMonth?: number | null | undefined;
        birthYear?: number | null | undefined;
        country?: string | null | undefined;
        email?: string | null | undefined;
        emailAddress?: string | null | undefined; // TODO: I think it should be only email, but here is defined as emailAddress packages/apps/NFLMobile/src/types/userTypes.ts
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        zip?: string | null | undefined;
    };
    regToken?: string;
    subscriptions?: {};
    uid?: string | null | undefined;
    UID?: string | null | undefined; // TODO: Check because if we send UID instead of uid in CTV it does not work.
    username?: string;
    preferences?: {
        terms?: {
            [key: string]: {
                isConsentGranted: boolean | null | undefined;
            };
        };
        privacy?: {
            [key: string]: {
                isConsentGranted: boolean | null | undefined;
            };
        };
    };
};

function parseResponse(response: any) {
    if (typeof response === "string") {
        return JSON.parse(response);
    }
    return response;
}

export class Gigya {
    static isInitialized = defer<void>();

    static async initDefault() {
        Gigya.isInitialized.resolve();
    }

    static setGigyaModule() {
        /*
            When this file is imported Platform.isTV is undefined.
            This method is to select the correct SDK (for CTV) once gigya is instantiated
        */

        if (Platform.isTV) {
            // @ts-expect-error FixMe
            GigyaModule = GigyaRestSDK.shared;
        } else if (Platform.OS === "web") {
            // @ts-expect-error FixMe
            GigyaModule = GigyaWebSDK.shared;
        }
    }

    static async init(apiKey: string, apiDomain: string, cname?: string) {
        await GigyaModule.initSDK(apiKey, apiDomain, cname);

        Gigya.isInitialized.resolve();
    }

    static async isSessionValid(): Promise<any> {
        await Gigya.isInitialized.promise;
        return GigyaModule.isSessionValid();
    }

    static async login(
        loginId: string,
        password: string,
        params?: LoginParams | null | undefined,
        include?: string | null
    ): Promise<any> {
        await Gigya.isInitialized.promise;
        try {
            await GigyaModule.clearSession();
            const response = await GigyaModule.login(loginId, password, {
                ...(params || {}),
                include: include || "loginIDs,profile,subscriptions,data",
            });

            return parseResponse(response);
        } catch (error) {
            const throwable = {
                errors: parseLoginError(error),
                _originalError: error,
            };
            throw throwable;
        }
    }

    static async sso(params?: any): Promise<any> {
        await Gigya.isInitialized.promise;
        try {
            await GigyaModule.clearSession();
            const response = await GigyaModule.sso(params || {});
            return parseResponse(response);
        } catch (error) {
            const throwable = {
                errors: parseLoginError(error),
                _originalError: error,
            };

            throw throwable;
        }
    }

    static async getConflictingAccount(
        regToken: string
    ): Promise<ConflictingAccountResponse> {
        await Gigya.isInitialized.promise;
        try {
            const response = await GigyaModule.getConflictingAccount(regToken);
            const json = parseResponse(response);
            return {
                loginId: json?.conflictingAccount?.loginID,
                loginProviders: json?.conflictingAccount?.loginProviders,
            };
        } catch (error) {
            const throwable = {
                errors: parseLoginError(error),
                _originalError: error,
            };

            throw throwable;
        }
    }

    static async logout(): Promise<any> {
        await Gigya.isInitialized.promise;
        const response = await GigyaModule.logout();
        return parseResponse(response);
    }

    static shapeUserData(params: RegisterUserParams, include?: string) {
        return {
            include: include || "loginIDs",
            profile: JSON.stringify({
                email: params.email,
                country: params.country,
                firstName: params.firstName,
                lastName: params.lastName,
                birthDay: params.birthDay,
                birthMonth: params.birthMonth,
                birthYear: params.birthYear,
                ...(!!params.zip ? {zip: params.zip} : {}),
            }),
            ...(params.username ? {username: params.username} : {}),
            finalizeRegistration: true,
            regSource: params.regSource,
            subscriptions: JSON.stringify({
                "global.email": {
                    isSubscribed: params.optIn,
                },
                "nfl_fantasy.email": {
                    isSubscribed: false,
                },
                "team_recap.email": {
                    isSubscribed: false,
                },
                "nfl_products.email": {
                    isSubscribed: false,
                },
                "daily_newsletter.email": {
                    isSubscribed: false,
                },
                "nfl_news.email": {
                    isSubscribed: false,
                },
                "nfl_programming.email": {
                    isSubscribed: false,
                },
                "nfl_network_weekly.email": {
                    isSubscribed: false,
                },
                "nfl_partner_offers.email": {
                    isSubscribed: false,
                },
                "nfl_events.email": {
                    isSubscribed: false,
                },
                "nfl_network_program.email": {
                    isSubscribed: false,
                },
                "nfl_mobile.email": {
                    isSubscribed: false,
                },
                "nfl_game_pass.email": {
                    isSubscribed: false,
                },
            }),
            data: JSON.stringify({
                lowered_email_hash: getMD5Hash(params.email),
                ...(params.favoriteTeam
                    ? {"league.favoriteTeams": [params.favoriteTeam]}
                    : {}),
            }),
        };
    }

    static async register(
        params: RegisterUserParams,
        include?: String
    ): Promise<any> {
        await Gigya.isInitialized.promise;
        const {email, password} = params;
        try {
            const response = await GigyaModule.register(
                email,
                password,
                this.shapeUserData(params, include)
            );
            return parseResponse(response);
        } catch (error) {
            const throwable = {
                errors: parseCreateAccountError(error),
                _originalError: error,
            };
            throw throwable;
        }
    }

    static async finalizeRegistration(
        regToken: string,
        include?: string
    ): Promise<any> {
        await Gigya.isInitialized.promise;
        try {
            const response = await GigyaModule.finalizeRegistration({
                regToken,
                ...(include ? {include} : {}),
            });
            return parseResponse(response);
        } catch (error) {
            const throwable = {
                errors: parseUpdateUserError(error),
                _originalError: error,
            };
            throw throwable;
        }
    }

    static async getAccount(params?: {
        uid: string;
        include?: string;
    }): Promise<any> {
        await Gigya.isInitialized.promise;
        try {
            const response = await GigyaModule.getAccount(params || {});
            return parseResponse(response);
        } catch (error) {
            const throwable = {
                errors: parseUpdateUserError(error),
                _originalError: error,
            };
            throw throwable;
        }
    }

    static async verifyLogin(params?: {UID: string}): Promise<any> {
        await Gigya.isInitialized.promise;
        try {
            const response = await GigyaModule.verifyLogin(params || {});
            return parseResponse(response);
        } catch (error) {
            const throwable = {
                errors: parseUpdateUserError(error),
                _originalError: error,
            };
            throw throwable;
        }
    }

    static async getAccountAdditionalData(
        params?: {
            uid: string;
            include?: string;
        },
        retryCall = 2,
        counter = 0
    ): Promise<any> {
        // this endpoint call might be changed in the future(new gigya calls, if the time to update the database pointing to the accounts.search is optimized we should delete the retry logic using recursion)
        const wait = (ms) =>
            new Promise((resolve) => {
                setTimeout(() => resolve(), ms);
            });
        await Gigya.isInitialized.promise;
        let response;

        try {
            if (counter < retryCall) {
                await wait(counter > 0 ? 6000 : 0);
                response = await GigyaModule.getAccountAdditionalData(
                    params || {}
                );

                if (
                    !response?.results?.[0]?.preferences?.nfltermsofservice2023
                        ?.isConsentGranted &&
                    !response?.results?.[0]?.privacy?.nflprivacypolicy2023
                        ?.isConsentGranted
                ) {
                    const result =
                        (await Gigya.getAccountAdditionalData(
                            params || {},
                            retryCall,
                            counter + 1
                        )) || parseResponse(response);

                    return result;
                }

                return parseResponse(response);
            }

            return null;
        } catch (error) {
            const throwable = {
                errors: parseUpdateUserError(error),
                _originalError: error,
            };

            throw throwable;
        }
    }

    static async setAccount(
        params: UpdateGigyaAccountParams,
        useStringify = true
    ): Promise<any> {
        const {UID, uid, ...updateParams} = params || {};
        const {data, profile, subscriptions, preferences} = updateParams || {};

        // In CTV, if we stringify the values the endpoint fails
        const stringifiedParams = useStringify
            ? {
                  ...(data && {data: JSON.stringify(data)}),
                  ...(profile && {profile: JSON.stringify(profile)}),
                  ...(preferences && {
                      preferences: JSON.stringify(preferences),
                  }),
                  ...(subscriptions && {
                      subscriptions: JSON.stringify(subscriptions),
                  }),
              }
            : {};

        await Gigya.isInitialized.promise;
        try {
            let response = await GigyaModule.setAccount({
                ...updateParams,
                ...stringifiedParams,
                uid: uid || UID,
            });

            if (!response?.UID) {
                // in the iOS SDK, the response is already a JSON object of logged in user data
                // on android it seems like we need to fetch getAccount.
                response = await GigyaModule.getAccount({
                    include: "data,profile",
                    uid: UID,
                });
            }
            return parseResponse(response);
        } catch (error) {
            // NOTE: there is a bug in the iOS api where when setAccount is being used to update birthday and other required fields
            // the getAccount API call isn't passing in the regToken.
            if (!!params.regToken && error.code !== 403007) {
                return null;
            }
            const throwable = {
                errors: parseUpdateUserError(error),
                _originalError: error,
            };
            throw throwable;
        }
    }

    static async getAuthCode(): Promise<any> {
        await Gigya.isInitialized.promise;
        return GigyaModule.getAuthCode();
    }
}
