import {
    getRefreshableNFLToken,
    removeNFLToken as removeRefreshableNFLToken,
} from "@nfl/rn-shared/src/utils/getRefreshableNFLToken";

// types
import type {UserStateType} from "@nfl/rn-app-nfl-fantasy/src/reducers/userReducer";

// utils
import {getGUID} from "@nfl/rn-app-nfl-fantasy/src/utils/getGUID";
import {fetchNFLTokenSSR} from "./fetchNFLTokenSSR";

const NFL_REFRESH_TOKEN_KEY = "nfl.fantasy.v3.refreshableToken";

export const removeNFLAuthToken = () => {
    return removeRefreshableNFLToken({NFLTokenKey: NFL_REFRESH_TOKEN_KEY});
};

export function getNFLAuthTokenConfig({userState}: {userState: UserStateType}) {
    const uid = userState.nfl?.gigyaUID;

    // return SSR method
    if (typeof window === "undefined") {
        return () => {
            return fetchNFLTokenSSR({
                clientKey: String(process.env.NFL_API_CLIENT_KEY),
                clientSecret: String(process.env.NFL_API_CLIENT_SECRET),
            });
        };
    }

    return async () => {
        const deviceId = getGUID();

        return getRefreshableNFLToken({
            NFLTokenKey: NFL_REFRESH_TOKEN_KEY,
            clientKey: String(process.env.NFL_API_CLIENT_KEY),
            clientSecret: String(process.env.NFL_API_CLIENT_SECRET),
            deviceId,
            uid,
        });
    };
}
