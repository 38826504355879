import jwtDecode, {JwtPayload} from "jwt-decode";
import {
    NFLRefreshableToken,
    NFLTokenRequestType,
} from "@nfl/nfl-api/src/NFLToken/NFLRefreshableToken";
import {Gigya} from "@nfl/react-native-gigya/src/Gigya";

// sdk
import {NFLAPIConfig} from "@nfl/nfl-api/src/NFLAPIConfig/NFLAPIConfig";

// utils
import {Platform} from "react-native";
import {storage} from "../stores/localStorage";
import {
    fetchRefreshableNFLToken,
    fetchRefreshableNFLRefreshToken,
} from "./fetchRefreshableNFLToken";
import {getCurrentLocation} from "./geolocation";
import {synchronizedPromise} from "./promise";
import {axiosInstance} from "./api/axiosInstance";

const TOKEN_EXPIRATION_LEEWAY = 60000; // 1 minute
const LOCATION_REQUEST_TIMEOUT = 3000; // milliseconds
const MAX_REFRESH_RETRIES = 1;

type TokenSync = NFLTokenRequestType & {
    clearLoginCreds?: () => Promise<any>;
    NFLTokenKey: string;
    onGigyaGetAccount?: (account: any) => void;
    refreshRetries?: number;
    verifyAuthenticatedUserLogin?: boolean;
};

export const removeNFLToken = async ({
    NFLTokenKey,
    removeRefreshToken = true,
}: {
    NFLTokenKey: string;
    removeRefreshToken?: boolean;
}) => {
    return Promise.all([
        removeRefreshToken
            ? storage.remove({key: `${NFLTokenKey}.refreshToken`})
            : null,
        storage.remove({key: NFLTokenKey}),
    ]);
};

export async function getRefreshToken({NFLTokenKey}: {NFLTokenKey: string}) {
    const refreshTokenKey = `${NFLTokenKey}.refreshToken`;
    return storage
        .load({
            key: refreshTokenKey,
            autoSync: true,
            syncInBackground: false,
        })
        .catch(() => {
            return null;
        });
}

function saveToken({
    NFLTokenKey,
    token,
    withoutLeeway = false,
}: {
    NFLTokenKey: string;
    token: NFLTokenType;
    withoutLeeway?: boolean;
}) {
    const now = Date.now();
    const leeway = withoutLeeway ? 0 : TOKEN_EXPIRATION_LEEWAY;
    const expires = (token.exp ?? 0) * 1000 - now - leeway;

    return storage.save({
        key: NFLTokenKey,
        data: token,
        expires: Math.max(0, expires),
    });
}

export async function getRefreshableNFLTokenSync(
    tokenParams: TokenSync
): Promise<NFLTokenType> {
    const refreshTokenKey = `${tokenParams.NFLTokenKey}.refreshToken`;
    let tokenPromise: Promise<NFLTokenType>;

    let gigyaPayload: {
        UIDSignature?: string;
        signatureTimestamp?: string;
        errorCode?: number;
    } = {};

    const signatureTimestampExpired = tokenParams.signatureTimestamp
        ? new Date(
              Number(tokenParams.signatureTimestamp ?? 0) * 1000
          ).getTime() < Date.now()
        : false;

    if (tokenParams.verifyAuthenticatedUserLogin && tokenParams.uid) {
        try {
            gigyaPayload = await Gigya.verifyLogin({
                UID: tokenParams.uid,
            });
        } catch (e) {
            await Promise.all([
                removeNFLToken({NFLTokenKey: tokenParams.NFLTokenKey}),
                tokenParams.clearLoginCreds?.(),
            ]);
            throw e._originalError || e;
        }
    } else if (
        tokenParams.uid &&
        ((!tokenParams.signatureTimestamp &&
            !tokenParams.uidSignature &&
            !tokenParams.refreshToken?.refreshToken) ||
            (!tokenParams.refreshToken?.refreshToken &&
                signatureTimestampExpired &&
                tokenParams.onGigyaGetAccount))
    ) {
        // there are 2 scenarios where we need to get the account from gigya
        // (1) app believes we are logged in (uid exists) but we don't have a timestamp
        //     or signature and we are calling /token instead of /refresh
        // (2) we are going to call /token call and the signature timestamp is expired
        //     for now we will only go to this case if the gigya information can be written back to the app
        //     via the onGigyaGetAccount callback
        try {
            gigyaPayload = await Gigya.getAccount({uid: tokenParams.uid});
            tokenParams.onGigyaGetAccount?.(gigyaPayload);
            if (gigyaPayload.errorCode) {
                throw gigyaPayload;
            }
        } catch (e) {
            throw e._originalError || e;
        }
    }

    if (tokenParams.refreshToken?.refreshToken) {
        let locationPromise;
        if (tokenParams.customLocation) {
            locationPromise = Promise.resolve(tokenParams.customLocation);
        } else if (Platform.OS === "web") {
            // Do not request location on web
            locationPromise = Promise.resolve(null);
        } else {
            locationPromise = getCurrentLocation({
                enableHighAccuracy: false,
                skipPermissionRequests: tokenParams.skipPermissionRequests,
                timeout: LOCATION_REQUEST_TIMEOUT,
            }).catch(() => null);
        }
        tokenPromise = locationPromise.then((location) => {
            return fetchRefreshableNFLRefreshToken({
                ...tokenParams,
                signatureTimestamp:
                    gigyaPayload?.signatureTimestamp ||
                    tokenParams.signatureTimestamp,
                uidSignature:
                    gigyaPayload?.UIDSignature || tokenParams.uidSignature,
                refreshToken: tokenParams.refreshToken?.refreshToken || "",
                ...(location ? {location} : {}),
            });
        });
    } else {
        tokenPromise = fetchRefreshableNFLToken({
            ...tokenParams,
            signatureTimestamp:
                gigyaPayload?.signatureTimestamp ||
                tokenParams.signatureTimestamp,
            uidSignature:
                gigyaPayload?.UIDSignature || tokenParams.uidSignature,
        });
    }

    try {
        const token = await tokenPromise;

        if (token) {
            await saveToken({token, NFLTokenKey: tokenParams.NFLTokenKey});
        }
        if (token?.refreshToken) {
            await storage.save({
                key: refreshTokenKey,
                data: {
                    refreshToken: token.refreshToken,
                    claims: token.claims || [],
                },
                expires: null,
            });
        }

        return token;
    } catch (e) {
        if (
            tokenParams?.refreshRetries &&
            tokenParams?.refreshRetries <= MAX_REFRESH_RETRIES
        ) {
            // last retry abandon all ship and logout of gigya
            const lastDitch =
                tokenParams.refreshRetries === MAX_REFRESH_RETRIES &&
                !!tokenParams.clearLoginCreds;
            if (lastDitch) {
                await Promise.all([
                    removeNFLToken({NFLTokenKey: tokenParams.NFLTokenKey}),
                    tokenParams.clearLoginCreds?.(),
                ]);
            }

            if (e.errorCode === 404) {
                await removeNFLToken({NFLTokenKey: tokenParams.NFLTokenKey});
            }

            const thirdPartyAuthParams = [
                "amazonPrimeUserId",
                "amazonPrimeUUID",
                "espnPlusUserId",
                "espnPlusUUID",
                "idp",
                "mvpdUserId",
                "mvpdUUID",
                "mvpdIdp",
                "paramountPlusUserId",
                "paramountPlusUUID",
                "peacockUserId",
                "peacockUUID",
                "identityProviderId",
                "signatureTimestamp",
                "uidSignature",
                "uid",
                "youTubeUserId",
                "youTubeUUID",
            ];
            const thirdPartyAuthRemovedParams = {...tokenParams};
            thirdPartyAuthParams.forEach(
                (param) => delete thirdPartyAuthRemovedParams[param]
            );

            const finalTokenParams = lastDitch
                ? {
                      ...thirdPartyAuthRemovedParams,
                      refreshToken:
                          e.errorCode === 404 ? null : tokenParams.refreshToken,
                      refreshRetries: tokenParams.refreshRetries + 1,
                  }
                : {
                      ...tokenParams,
                      refreshToken:
                          e.errorCode === 404 ? null : tokenParams.refreshToken,
                      refreshRetries: tokenParams.refreshRetries + 1,
                  };

            return getRefreshableNFLTokenSync(finalTokenParams);
        }
        throw e;
    }
}

export async function getRefreshableNFLToken(
    refreshRequestParams: {forceRefresh?: boolean} & TokenSync
): Promise<NFLTokenType> {
    const promiseSyncParams = {
        amazonPrimeUserId: refreshRequestParams.amazonPrimeUserId,
        peacockUserId: refreshRequestParams.peacockUserId,
        clientKey: refreshRequestParams.clientKey,
        clientSecret: refreshRequestParams.clientSecret,
        customLocation: refreshRequestParams.customLocation,
        deviceId: refreshRequestParams.deviceId,
        deviceInfo: refreshRequestParams.deviceInfo,
        espnPlusUserId: refreshRequestParams.espnPlusUserId,
        espnPlusUUID: refreshRequestParams.espnPlusUUID,
        forceRefresh: refreshRequestParams.forceRefresh,
        identityProviderId: refreshRequestParams.identityProviderId,
        mvpdUserId: refreshRequestParams.mvpdUserId,
        mvpdUUID: refreshRequestParams.mvpdUUID,
        mvpdIdp: refreshRequestParams.mvpdIdp,
        networkType: refreshRequestParams.networkType,
        requireLocation: refreshRequestParams.requireLocation,
        skipPermissionRequests: refreshRequestParams.skipPermissionRequests,
        uid: refreshRequestParams.uid,
        youTubeUserId: refreshRequestParams.youTubeUserId,
    };
    return synchronizedPromise(
        `refreshable-${refreshRequestParams.NFLTokenKey}`,
        promiseSyncParams,
        async () => {
            storage.sync = {
                ...storage.sync,
                [refreshRequestParams.NFLTokenKey]: async ({
                    syncParams,
                }: {
                    syncParams: any;
                }) =>
                    synchronizedPromise(
                        refreshRequestParams.NFLTokenKey,
                        promiseSyncParams,
                        () =>
                            getRefreshableNFLTokenSync({
                                ...refreshRequestParams,
                                refreshToken: syncParams.refreshToken,
                                skipPermissionRequests:
                                    syncParams.skipPermissionRequests,
                            })
                    ),
            };
            const [refreshToken, token] = await Promise.all([
                getRefreshToken({
                    NFLTokenKey: refreshRequestParams.NFLTokenKey,
                }),
                storage
                    .load({
                        key: refreshRequestParams.NFLTokenKey,
                        autoSync: false,
                        syncInBackground: false,
                    })
                    .catch(() => {
                        return null;
                    }),
            ]);

            const claims = NFLRefreshableToken.claimsFromParams({
                amazonPrimeUserId: refreshRequestParams.amazonPrimeUserId,
                espnPlusUserId: refreshRequestParams.espnPlusUserId,
                mvpdUserId: refreshRequestParams.mvpdUserId,
                paramountPlusUserId: refreshRequestParams.paramountPlusUserId,
                peacockUserId: refreshRequestParams.peacockUserId,
                uid: refreshRequestParams.uid,
                youTubeUserId: refreshRequestParams.youTubeUserId,
            });

            // TODO might need to do some work here for some legacy users.
            if (
                !NFLRefreshableToken.claimsEquals(
                    refreshToken?.claims,
                    claims
                ) ||
                refreshRequestParams.forceRefresh
            ) {
                // if claims are different we need to upgrade/downgrade
                await removeNFLToken({
                    NFLTokenKey: refreshRequestParams.NFLTokenKey,
                    removeRefreshToken: false,
                });
            } else if (!!token) {
                // in most cases, token doesn't need upgrade and hasn't changed or timed out
                // we can return the token we just loaded instead of loading again.
                return token;
            }

            // token doesn't exist or we just cleared it out because we are upgrade/downgrading
            return storage.load({
                key: refreshRequestParams.NFLTokenKey,
                autoSync: true,
                syncInBackground: false,
                syncParams: {
                    skipPermissionRequests:
                        refreshRequestParams.skipPermissionRequests,
                    refreshToken,
                },
            });
        }
    );
}

export async function fetchMultipleGrantPlans({
    accessToken,
}: {
    accessToken: string;
}) {
    const allPlansResponse = await fetch(
        `${NFLAPIConfig.baseUrl}/payments/v2/listpassgrants`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );

    if (!allPlansResponse.ok) {
        throw allPlansResponse;
    }

    const allPlansResponseJson = await allPlansResponse.json();

    return allPlansResponseJson;
}

const parseBillingType = (billingType: string) => {
    switch (billingType) {
        case "year":
            return "annual";

        case "month":
            return "monthly";

        default:
            return billingType;
    }
};

export async function createTemporaryGrant({
    NFLTokenKey,
    authToken,
    billingType,
    plan,
    withoutLeeway = false,
}: {
    NFLTokenKey: string;
    authToken: NFLTokenType;
    billingType?: string;
    plan: string;
    withoutLeeway?: boolean;
}) {
    const grantUrl = `${NFLAPIConfig.baseUrl}/identity/v3/tempGrant`;
    const {accessToken} = authToken;
    const refreshToken = await getRefreshToken({NFLTokenKey});

    const response = await axiosInstance.request({
        url: grantUrl,
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            ...(billingType
                ? {billingType: parseBillingType(billingType)}
                : {}),
            plan,
            refreshToken: refreshToken?.refreshToken,
        },
    });

    if (response.status !== 200) {
        throw response;
    }

    if (!response?.data?.accessToken) {
        throw response;
    }

    const decoded = jwtDecode<JwtPayload>(response?.data?.accessToken);
    const token = {...decoded, ...response?.data};

    await saveToken({token, NFLTokenKey, withoutLeeway});

    return token;
}
